<template>
  <div class="transcript--footer">
    <p class="mb-3">
      This document may not be reproduced, distributed, or transmitted
      in any form or by any means including resale of any part,
      unauthorized distribution to a third party or other electronic
      methods, without the prior written permission of Tegus, Inc.
    </p>

    <p>
      Tegus is not a registered investment advisor or broker-dealer,
      and is not licensed nor qualified to provide investment
      advice. The information published in this transcript
      (&ldquo;Content&rdquo;) is for information purposes only and
      should not be used as the sole basis for making any investment
      decision.  Tegus, Inc. (&ldquo;Tegus&rdquo;) makes no
      representations and accepts no liability for the Content or for
      any errors, omissions, or inaccuracies will in no way be held
      liable for any potential or actual violations of United States
      laws, including without limitation any securities laws, based on
      Information sent to you by Tegus. The views of the expert
      expressed in the Content are those of the expert and they are
      not endorsed by, nor do they represent the opinion of,
      Tegus. Tegus reserves all copyright, intellectual and other
      property rights in the Content. The Content is protected by the
      Copyright Laws of the United States and may not be copied,
      reproduced, sold, published, modified or exploited in any way
      without the express written consent of Tegus.
    </p>
  </div>
</template>

<script lang="ts">
export default {
  name: 'TranscriptDisclaimer'
}
</script>

<script setup lang="ts">
// Empty Script Setup
</script>

<style lang="scss" scoped>
.transcript--footer {
  @include background-primary-blue-04;
  @include color-gray-3-60;
  @include font-paragraph-p3-small-compact;

  margin-top: 40px;
  padding: 20px;
}

@media print {
  .transcript--footer {
    background: none;
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
